import { gsap,Power3} from 'gsap';
import $ from 'jquery'

const pageLoader = () =>{
    var circleEl = document.querySelector('.skills-graph-circle'),
    progressBar = circleEl.querySelector('.skills-graph-circle__bar'),
    r = progressBar.getAttribute('r'),
    circumference = 2 * Math.PI * r,
    countEl = document.querySelector('.skills-graph-circle__count'),
    countVal = parseInt(countEl.getAttribute('data-skills-graph-perc'));
    var smth = $(circleEl).animate({
        value: countVal
    }, {
        duration: 1500,
        easing: 'linear',
        step: function (currentValue) {
            progressBar.style.strokeDashoffset = circumference - (currentValue * circumference / 100);
            countEl.textContent = Math.ceil(currentValue);
        }
    });
    document.addEventListener('DOMContentLoaded', function() {
        setTimeout(() => {
            gsap.to('.page__loader-progress',{opacity:0,duration:0.2,ease:Power3.easeInOut})
            gsap.to('.page__loader-left',{x:'-105%',duration:0.5,ease:Power3.easeInOut,delay:0.3})
            gsap.to('.page__loader-right',{x:'105%',duration:0.5,ease:Power3.easeInOut,delay:0.3})
            gsap.to('.page__loader',{display:'none',delay:1})
        }, 1000);
     }, false);
}

export default pageLoader