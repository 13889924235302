import { gsap,Back,Power0,Power3} from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger);

const scrollableSection = (hasSlider,leftSectionCount,rightSectionCount) => {
    let dss_swiper;
    let dss = {
            responsive_size:768,  
            elements:{
                dss_allSection:document.querySelectorAll('.dss__sc'),
                dss__sc_swiper:document.querySelectorAll('.dss__sc_swiper'),
                add_swiper_wrapper:document.querySelectorAll('.dss__sc_swiper .dss__sc-scrollable-content'),
                dss__sc_content:document.querySelectorAll('.dss__sc_swiper .dss__sc-content'),
                sliderNavigations:document.querySelectorAll('.slider__navigations')
            },
            init:{
                
                scrollTrigger:(dir,sectionCount)=>{
                    let scenes = gsap.utils.toArray(`${dir} .panel`),
                    snap = gsap.utils.snap(1/(scenes.length * 2 - 1)),
                    panel = document.querySelectorAll(`${dir} .panel`);
                    // Get All panels and content to apply the animations while scroll
                    let animationPanel = gsap.utils.toArray(`${dir} .panel`);
                    let contentCenter = gsap.utils.toArray(`${dir} .dss__sc-content--center`);
                    let contentBottom = gsap.utils.toArray(`${dir} .dss__sc-content--bottom`);
                    
                    scenes.forEach((scene, i) => {
                        ScrollTrigger.create({
                                trigger:`${dir} .panel`,
                                scroller:`${dir} .dss__sc-scrollable-content`,
                                start: 0,
                                end: panel[i].scrollHeight * (sectionCount * 2) + ' bottom',
                                markers: false,
                                scrub: 0.2,
                                duration:0.3,
                                invalidateOnRefresh: false,
                                snap:{
                                    duration: {min: 1.0, max: 1.5},
                                    ease: Back.easeInOut.config(0.7),
                                    snapTo: value =>{
                                        let snapped = snap(value);
                                        if ((snapped*2) % (0.4*10) === 0) {return snapped + 0.0002;} 
                                        if ((snapped*2) % (0.4*10) !== 0 && (snapped*10) % (0.2*10) === 0) {return snapped - 0.0002;}
                                        return snapped;
                                    },
                                },
                                onEnterBack:()=>{
                                    if(hasSlider ===true){
                                        gsap.fromTo(contentCenter[i], { opacity: 0,y:'5rem'},{opacity:1,y:'0rem',duration:0.5,delay:0.2,ease:Power0.easeInOut});
                                        gsap.fromTo(contentBottom[i], { opacity: 0,y:'5rem'},{opacity:1,y:'0rem',duration:0.5,delay:0.2,ease:Power0.easeInOut});
                                    }
                                },
                                onEnter:() => {
                                    if(hasSlider===true){
                                        gsap.fromTo(contentCenter[i], { opacity: 0,y:'5rem'},{opacity:1,y:'0rem',duration:0.5,delay:0.2,ease:Power0.easeInOut});
                                        gsap.fromTo(contentBottom[i], { opacity: 0,y:'5rem'},{opacity:1,y:'0rem',duration:0.5,delay:0.2,ease:Power0.easeInOut});
                                    }
                                },
                                // Hide all elements when leave section to avoid flashing
                                onLeave:()=>{
                                    if(hasSlider===true){
                                        gsap.to(contentCenter[i], { opacity: 0});
                                        gsap.to(contentBottom[i], { opacity: 0});
                                    }
                                }
                            });
                        });
                },
                slider:()=>{

                   if(hasSlider === true){
                    dss.elements.sliderNavigations.forEach(element => {
                        element.classList.add('active')            
                    });
                    dss_swiper = new Swiper(".dss-swiper", {
                        grabCursor: true,
                        observer: true, 
                        resizeReInit: true,
                        a11y:false,
                        pagination: {
                            el: ".swiper-pagination",
                            type: "fraction",
                        },
                          navigation: {
                            nextEl: ".slider__navigations-button--right",
                            prevEl: ".slider__navigations-button--left",
                        }

                    });
                    if(window.innerWidth < dss.responsive_size){
                        dss_swiper.forEach(element => {
                            if(element.params.init){
                                let slider_title = `.${element.el.classList[1]} .dss__sc-content--center .title-24`;
                                gsap.to(slider_title, { opacity: 1,y:'3rem'});
                            }
                          
                            let slider_title = `.${element.el.classList[1]} .dss__sc-content--center .title-40`;
                            let slider_paragraph = `.${element.el.classList[1]} .dss__sc-content--center .title-26`;
                            let slider_img = `.${element.el.classList[1]} .dss__sc-content--center .data img.slider-animated`;

                            gsap.fromTo(slider_title, { opacity: 0,y:'10rem'},{opacity:1,y:'0rem',duration:0.4,ease:Power3.easeInOut});
                            gsap.fromTo(slider_paragraph, { opacity: 0,y:'10rem'},{opacity:1,y:0,duration:0.4});
                            gsap.fromTo(slider_img, { opacity: 0,y:'10rem'},{opacity:1,y:0,duration:0.4});

                            element.on('slideChange', function (e) {
                                // if(e.activeIndex === 0){
                                //     dss.elements.sliderNavigations.forEach(element => {
                                //         element.classList.add('invert-btns')
                                //     });
                                // }else{
                                //     dss.elements.sliderNavigations.forEach(element => {
                                //         element.classList.remove('invert-btns')
                                //     });
                                // }
                                let slider_title = `.${e.el.classList[1]} .dss__sc-content--center .title-40`;
                                let slider_paragraph = `.${e.el.classList[1]} .dss__sc-content--center .title-26`;
                                let slider_img = `.${e.el.classList[1]} .dss__sc-content--center .data img.slider-animated`;

                                gsap.fromTo(slider_title, { opacity: 0,y:'10rem'},{opacity:1,y:'0rem',duration:0.4,ease:Power3.easeInOut});
                                gsap.fromTo(slider_paragraph, { opacity: 0,y:'10rem'},{opacity:1,y:0,duration:0.4});
                                gsap.fromTo(slider_img, { opacity: 0,y:'10rem'},{opacity:1,y:0,duration:0.4});
                            });
                        });
                    }
                   }

                },
                desktop:()=>{
                    window.addEventListener('load',()=>{
                        if(window.innerWidth > dss.responsive_size){
                            // init dss in Desktop after load
                            dss.init.scrollTrigger('.dss-left', leftSectionCount)
                            dss.init.scrollTrigger('.dss-right', rightSectionCount)
                        }
                    })
                    // Remove Swiper then init DSS
                    if(window.innerWidth < dss.responsive_size){
                       window.addEventListener('resize',()=>{
                        if(window.innerWidth > dss.responsive_size){
                            if(hasSlider === true){
                                if(dss_swiper){
                                    dss.managers.swiper('remove')
                                    dss.elements.dss__sc_content.forEach(element => {
                                        element.removeAttribute('style')
                                    });
                                    dss.init.scrollTrigger('.dss-left', leftSectionCount)
                                    dss.init.scrollTrigger('.dss-right', rightSectionCount)
                                }
                            }
                        }
                       }) 
                    }
                },
                mobile:()=>{
               
                    // Add swiper direct if screen less than 768px
                    window.addEventListener('load',()=>{
                        // ``````````````-----If now slider and need in Responsive to make the height is flexable with the content-----```````
                            if(hasSlider === false){
                                if(window.innerWidth < dss.responsive_size){
                                    dss.elements.dss_allSection.forEach(element => {
                                        element.classList.add('heightAuto')
                                    });

                                }else{
                                    dss.elements.dss_allSection.forEach(element => {
                                        element.classList.remove('heightAuto')
                                    });  
                                }
                            }
                            // ``````````````----------````````
    
                        if(window.innerWidth < dss.responsive_size){
                            // add Swiper classes
                            if(hasSlider === true){
                                dss.managers.swiper('add')
                            }
                        }
                    })
                    // Add swiper direct if screen resize from desktop to mobile
                    window.addEventListener('resize',()=>{

                        // ``````````````-----If now slider and need in Responsive to make the height is flexable with the content-----```````
                        if(hasSlider === false){
                            if(window.innerWidth < dss.responsive_size){
                                dss.elements.dss_allSection.forEach(element => {
                                    element.classList.add('heightAuto')
                                });
                                // init dss in Desktop after load
                                dss.init.scrollTrigger('.dss-left', leftSectionCount)
                                dss.init.scrollTrigger('.dss-right', rightSectionCount)
                            }else if(window.innerWidth > dss.responsive_size){
                                dss.elements.dss_allSection.forEach(element => {
                                    element.classList.remove('heightAuto')
                                });  
                            }
                        }
                        // ``````````````----------````````



                        if(window.innerWidth < dss.responsive_size){
                            if(hasSlider === true){
                                dss.managers.swiper('add')
                                
                            }
                        }
                    })
                }
            },
            managers:{
                 /**
                 *  ~~~~~~~~~~~~~~~~~~
                 *  1) this function use to add all needed Swiper classes 
                 *  2) it's called every time mobile screen is fired
                 *  3) also it's called with remove event when screen mobile is leave to remove all swiper classes
                 *  ~~~~~~~~~~~~~~~~~~
                 * **/ 
                swiper:(type)=>{
                   if(hasSlider === true){
                    if(type === 'add'){
                        dss.elements.dss__sc_swiper.forEach(element => {
                            element.classList.add('dss-swiper');
                        });
                        dss.elements.add_swiper_wrapper.forEach(element => {
                            element.classList.add('swiper-wrapper');
                        });
                        dss.elements.dss__sc_content.forEach(element => {element.classList.add('swiper-slide')});

                    }else if(type === 'remove'){
                        dss.elements.dss__sc_swiper.forEach(element => {
                            element.classList.remove('dss-swiper');
                        });
                        dss.elements.add_swiper_wrapper.forEach(element => {
                            element.classList.remove('swiper-wrapper');
                        });
                        dss.elements.dss__sc_content.forEach(element => {element.classList.remove('swiper-slide')});
                    }
                    dss.init.slider();
                   }
                },
            },
        }
        dss.init.desktop()
        dss.init.mobile()
}




export default scrollableSection