
 const readMore = () =>{

    let readParent = document.querySelectorAll('.read-more-container');

    readParent.forEach(element => {
        element.addEventListener('click', event=>{
            const current = event.target;
    
            const isReadMoreBtn = current.className.includes('read-more-btn')
    
            if(!isReadMoreBtn) return;
    
            const currentText = event.target.parentNode.querySelector('.read-more-text');
    
            currentText.classList.toggle('read-more-text--show');
    
            current.textContent = current.textContent.includes('read more') ? "Read Less..." : "Read More...";
        })
    });

  



}   

export default readMore 