
import Home from './pages/Home';
import About from './pages/About';
import Team from './pages/Team';
import Brand_Info1 from './pages/brand-info';
import Brand_Info2 from './pages/brand-info2';


import pageLoader from './components/pageLoader';
import menu from './components/menu';
import form from './components/form'
import cursor from './components/cursor';

pageLoader();
menu();
form();
//cursor()

document.body.classList.contains('home__page') ? Home() : null;
document.body.classList.contains('about__page') ? About() : null;
document.body.classList.contains('team__page') ? Team() : null;
document.body.classList.contains('brand__info1') ? Brand_Info1() : null;
document.body.classList.contains('brand__info2') ? Brand_Info2() : null;