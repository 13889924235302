import gsap,{Power3} from 'gsap';
import pageLoader from './pageLoader';

const menu = () => {
     
    const menu = {
        
        elements:{
            menuBtn: document.querySelector('.menu__btn'),
            menu_header:document.querySelectorAll('.menu__list-header'),
            menuItem:document.querySelectorAll('.menu__list-item')

        },

        init: () => {
            
            menu.elements.menu_header.forEach(element => {
                // add active class for clicked element then remove from others
                element.addEventListener('click',()=>{
                    menu.elements.menu_header.forEach(element => {
                        element.nextSibling.classList.remove('active')
                    });
                    element.nextSibling.classList.toggle('active')
                })
            });

       
         

        },
        animation:() =>{
            let tl = gsap.timeline();
            tl.fromTo('.menuWindow',{display:'none'},{display:'flex',duration:0.1})
            tl.fromTo('.menu__door--left',{display:'none',width:'0'},{display:'flex',width:'50%',duration:0.4},'-=0.1')
            tl.fromTo('.menu__door--right',{display:'none',width:'0'},{display:'flex',width:'50%',duration:0.4,},'-=0.4')
            
            tl.fromTo('.menu',{display:'none',opacity:0},{display:'flex',opacity:1,duration:0.3,ease:'circ.in'})

            tl.fromTo('.menu__list-header',{opacity:0,y:'5rem'},{opacity:1,y:'0rem', stagger:0.2,duration:0.3},'-=0.2')
            tl.fromTo('.menu__list',{opacity:0,y:'4rem'},{opacity:1,y:'0rem',stagger:0.2,duration:0.3},'-=0.5')
            tl.pause()
            tl.reverse()
            menu.elements.menuBtn.addEventListener('click',()=>{
                tl.reversed(!tl.reversed());
            })
        }
    }


    window.addEventListener('resize', function () {window.innerWidth < 580 ? menu.init() : null;})
    window.addEventListener('load', function () {window.innerWidth < 580 ? menu.init() : null;})
    menu.animation()


   
}

export default menu