import $ from 'jquery';
import 'jquery-validation';

const form = () => {
    // $('#form button').on('click',function(e){
    //     e.preventDefault()
    //     console.log('sasasa');


        
    // })


    $("#form").validate({
        success: function(element, errorClass) {
			$(errorClass).parent().addClass('form-group--success');
			$(errorClass).parent().removeClass('form-group--error');
		},
        errorPlacement: function(error, element) {
			$(element).parent().removeClass('form-group--success');
			$(element).parent().addClass('form-group--error');
        },
        rules: {
            firstname: {
                required:true,
                minlength:2,
            
            },
            lastname: "required",
            email: {
                required: true,
                email: true
            },
            mobile: {
                required: true,
            },
            message:{
                required: true,
                minlength:10,
            }
        },
        messages: {
            firstname: {
                required:"Please enter your firstname",
                minlength:"Please enter at least 2 characters"
            },
            lastname: "Please enter your lastname",
            email: "Please enter a valid email address",
            mobile: "Please select at least 2 topics",
            message: "Please write your message"
        },
        submitHandler: function(form) {
            form.submit();
        }
    })
}

export default form